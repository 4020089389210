.snackbar
  position: fixed
  top: 22px
  right: -112px
  padding: 2px 7px
  border-radius: 8px
  display: flex
  align-items: center
  opacity: 0
  animation: opacify 0.3s forwards
  z-index: 9999
  font-size: 12px
  color: #333333

  .crossIcon
    transform: scale(0.7)
    filter: brightness(0) saturate(100%) invert(53%) sepia(6%) saturate(12%) hue-rotate(3deg) brightness(94%) contrast(90%)

.variantIcon
  transform: scale(0.7)
  margin-right: 4px

.beforeClose
  animation: opacifyBack
  animation-duration: 0.3s

.success
  border: 2px solid #53DA89
  background: #DDFFE4

.warning
  border: 2px solid #FFDF6F
  background: #FFFBED

.error
  border: 2px solid #E57B95
  background: #FFF2F7

.info
  border: 2px solid #58a2de
  background: #daf1ff

@keyframes opacify
  from
    opacity: 0
    right: -112px

  to
    opacity: 1
    right: 16px

@keyframes opacifyBack
  from
    opacity: 1
    right: 16px

  to
    opacity: 0
    right: -112px

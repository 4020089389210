.main
  padding: 16px
  min-height: calc(100vh - 56px)

.buttonContent
  display: flex
  gap: 4px
  align-items: center
  svg
    path
      stroke: var(--tg-theme-button-text-color)

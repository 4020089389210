.menu
  height: 66px
  position: sticky
  bottom: 0
  border-radius: 12px 12px 0 0
  overflow: hidden
  background: var(--tg-theme-header-bg-color)
  color: var(--tg-theme-text-color)
  display: flex
  align-items: center
  gap: 12px
  padding: 0 6px
  z-index: 1005

.menuItem
  font-size: 12px
  color: var(--tg-theme-text-color)
  padding: 4px
  display: flex
  flex-direction: column
  align-items: center
  gap: 2px
  flex: 1
  border-radius: 6px
  cursor: pointer
  transition: background 0.2s

  svg
    path
      fill: var(--tg-theme-text-color) !important
    rect
      fill: var(--tg-theme-text-color) !important

  &.menuItemActive
    background: var(--tg-theme-button-color)
    color: var(--tg-theme-button-text-color)
    svg
      path
        fill: var(--tg-theme-button-text-color) !important
      rect
        fill: var(--tg-theme-button-text-color) !important

@media (hover: hover) and (pointer: fine)
  .menuItem:hover
    background: var(--tg-theme-button-color)

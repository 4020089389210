.loadingScreen
  background: var(--tg-theme-bg-color)
  color: var(--tg-theme-text-color)
  height: 100%
  font-weight: 900
  position: fixed
  width: 100%
  z-index: 9999
  display: flex
  flex-direction: column
  align-items: center
  justify-content: space-between
  padding: 140px 0 20px 0

.appName
  font-size: 42px
  font-weight: 900
  letter-spacing: 6px

.spinners
  display: grid
  grid-auto-flow: column
  gap: 34px

.spinner
  color: var(--tg-theme-text-color) !important

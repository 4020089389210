.button
  background: var(--tg-theme-button-color)
  color: var(--tg-theme-button-text-color)
  border-radius: 6px
  height: 35px
  width: 120px
  border: 0
  text-wrap: nowrap
  width: max-content
  padding: 0 16px
  font-size: 14px
  display: flex
  align-items: center
  &:disabled
    opacity: 0.7

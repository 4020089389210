.input
  border-radius: 6px
  height: 72px
  border: 1px solid var(--tg-theme-section-separator-color)
  padding: 0 6px
  font-size: 14px
  resize: none
  &:disabled
    background: transparent
    color: var(--tg-theme-text-color)
    opacity: 0.7
  &:focus-visible
    outline: 0
    border-color: var(--tg-theme-button-color)

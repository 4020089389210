.eventSettings
  position: absolute
  width: calc(100% - 100px)
  background: var(--tg-theme-header-bg-color)
  z-index: 9999

.overlay
  z-index: 9
  position: fixed
  left: 0
  top: 0
  width: 100vw
  max-width: 100%
  height: 100vh
  max-height: 100%

.option
  padding: 8px
  font-size: 14px
  display: flex
  gap: 6px
  align-items: center
  color: var(--tg-theme-text-color)
  &.disabled
    opacity: 0.7

.editTaskIcon
  path
    fill: var(--tg-theme-text-color)
.backContainer
  display: flex
  align-items: center
  gap: 4px
  cursor: pointer
  width: max-content
  margin-right: 35px
  transition: color .2s
  svg
    path
      transition: color .2s
      fill: var(--tg-theme-text-color)

.backText
  font-size: 14px
  color: var(--tg-theme-text-color)

@media (hover: hover) and (pointer: fine)
  .backContainer:hover
    svg
      path
        fill: var(--tg-theme-button-color)
    .backText
      color: var(--tg-theme-button-color)

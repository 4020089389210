:root {
  --tg-theme-bg-color: #212121;
  --tg-theme-text-color: #ffffff;
  --tg-theme-hint-color: #aaaaaa;
  --tg-theme-link-color: #8774e1;
  --tg-theme-button-color: #8774e1;
  --tg-theme-button-text-color: #ffffff;
  --tg-theme-secondary-bg-color: #0f0f0f;
  --tg-theme-header-bg-color: #212121;
  --tg-theme-accent-text-color: #8774e1;
  --tg-theme-section-bg-color: #212121;
  --tg-theme-section-header-text-color: #aaaaaa;
  --tg-theme-subtitle-text-color: #aaaaaa;
  --tg-theme-destructive-text-color: #e53935;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--tg-theme-accent-text-color);
  display: grid;
  height: 100vh;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-x: hidden;
  touch-action: none;
  background: var(--tg-theme-bg-color);
  color: var(--tg-theme-text-color);
}

body::-webkit-scrollbar {
  width: 0;
  height: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.input
  border-radius: 6px
  height: 36px
  border: 1px solid var(--tg-theme-section-separator-color)
  padding: 0 6px
  font-size: 14px
  width: 100%
  &:disabled
    background: transparent
    color: var(--tg-theme-text-color)
    opacity: 0.7

  &:focus-visible
    outline: 0
    border-color: var(--tg-theme-button-color)

.textError
  margin-top: 4px
  font-style: normal
  font-weight: 400
  font-size: 13px
  line-height: 20px
  color: #ed1414


.header
  background: var(--tg-theme-header-bg-color)
  padding: 16px
  border-radius: 0 0 12px 12px
  display: flex
  justify-content: space-between
  align-items: center

.progressBarContainer
  max-width: 25%
  width: 25%

.progressBarHead
  display: flex
  align-items: center
  justify-content: space-between
  margin-bottom: 6px

.period
  font-size: 10px
  color: var(--tg-theme-text-color)

.progress
  font-size: 10px
  font-weight: 400px
  color: var(--tg-theme-text-color)

.progressBar
  height: 5px !important

.settingsIcon
  path
    fill: var(--tg-theme-button-color)

.logoContainer
  font-size: 22px
  font-weight: 900
  letter-spacing: 2px
  display: flex
  align-items: flex-end
  height: 24px
  line-height: 24px
  color: var(--tg-theme-text-color)

.notes
  padding: 16px
  min-height: calc(100vh - 112px)
  max-height: calc(100vh - 112px)
  display: flex
  flex-direction: column
  gap: 16px
  overflow: hidden
  width: 100vw
  max-width: 100%
  -ms-overflow-style: none
  scrollbar-width: none
  overflow-x: hidden
  &::-webkit-scrollbar
    width: 0
    height: 0

.button
  min-height: 35px

.buttonContent
  display: flex
  gap: 4px
  align-items: center
  svg
    path
      stroke: var(--tg-theme-button-text-color)

.notesList
  display: flex
  flex-direction: column
  gap: 24px
  overflow: auto
  width: 100%
  max-width: 100%
  overflow-x: hidden
  -ms-overflow-style: none
  scrollbar-width: none
  &::-webkit-scrollbar
    width: 0
    height: 0

.notesGroup
  display: flex
  flex-direction: column
  gap: 8px
  width: 100%
  max-width: 100%
  -ms-overflow-style: none
  scrollbar-width: none
  overflow: visible
  &::-webkit-scrollbar
    width: 0
    height: 0

.groupDate
  font-size: 16px
  font-weight: 500
  color: var(--tg-theme-text-color)

.notesItems
  display: flex
  flex-direction: column
  gap: 8px
  width: 100%
  max-width: 100%

.notesItem
  width: 100%
  max-width: 100%
  border-radius: 6px
  background: var(--tg-theme-button-color)
  position: relative
  transition: transform 0.3s ease
  min-height: 57px

.swiped
  transform: translateX(-100px)
  border-radius: 0

.notesControls
  position: absolute
  right: 0
  top: 0
  bottom: 0
  width: 0
  height: 0
  display: flex
  align-items: center
  justify-content: center
  color: white
  cursor: pointer
  opacity: 0
  transition: background 0.3s ease, width .2s cubic-bezier(0, 1.34, 0, 1.4)
  border-radius: 0 6px 6px 0
  overflow: hidden
  visibility: hidden
  .noteControl
    width: 0
    transition: width .2s
  &.notesControlsActive
    width: 100px
    height: 100%
    opacity: 1
    visibility: visible
    .noteControl
      width: 50px

.notesItem.swiped .notesControls
  right: -100px

.notesControls:hover
  background: darkred

.noteControl
  width: 50px
  display: flex
  align-items: center
  justify-content: center
  height: 100%

.favoriteControl
  background: var(--tg-theme-hint-color)

.deleteControl
  background: #da0303

.noteInfo
  display: flex
  flex-direction: column
  gap: 2px
  padding: 8px

.noteTitle
  font-size: 14px
  font-weight: 500
  color: var(--tg-theme-button-text-color)
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis

.noteText
  font-size: 12px
  color: var(--tg-theme-button-text-color)
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis

.switch
  display: flex !important
  align-items: center !important
  margin: 0 !important
  label
    color: var(--tg-theme-subtitle-text-color)
    font-size: 14px
    margin-bottom: 4px
    line-height: 16px
    margin: 4px 0 0 8px
  input
    box-shadow: none
    border-color: var(--tg-theme-button-color)
    background-color: white
    &:disabled
      opacity: 0.8
    &:focus
      box-shadow: none
    &:checked
      background-color: #009c3e
      border-color: #009c3e

.modal
  display: flex !important
  div[class^="modal-dialog"]
    flex: 1
    display: flex

  div[class^="modal-content"]
    flex: 1
    background: var(--tg-theme-section-bg-color) !important
    display: flex
    align-items: center
    div[class^="modal-body"]
      background: var(--tg-theme-section-bg-color) !important
      width: 100%
      max-width: 1280px

.modalFull
  div[class^="modal-dialog"]
    height: 100%
    margin: 0
    background: white
    border-radius: 0
    width: 100%
    max-width: 100%
    min-height: 100%
    height: auto
    -ms-overflow-style: none
    scrollbar-width: none
    flex: 1
    width: 100%

  &::-webkit-scrollbar
    width: 0
    height: 0

  div[class^="modal-content"]
    border-radius: 0
    border: 0
    min-height: 100vh
  div[class^="modal-body"]
    padding: 0
    display: flex
    flex-direction: column

.modalHeader
  border: 0
  justify-content: space-between
  align-items: center
  width: 100%

.closeModal
  position: relative
  background: transparent
  width: 24px
  height: 24px
  cursor: pointer
  border: 0

.closeModal:before,
.closeModal:after
  content: ""
  position: absolute
  top: 11px
  left: 10px
  width: 16px
  height: 2px
  background: var(--tg-theme-button-color)

.closeModal:before
  webkit-transform: rotate(45deg)
  transform: rotate(45deg)

.closeModal:after
  webkit-transform: rotate(-45deg)
  transform: rotate(-45deg)

.title
  font-size: 14px !important
  color: var(--tg-theme-text-color) !important

.centerSmallModal
  top: 50% !important
  transform: translateY(-50%) !important
  height: max-content !important

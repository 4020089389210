.datePicker
  width: 100%
  border-radius: 6px
  height: 36px
  border: 1px solid var(--tg-theme-section-separator-color)
  padding: 0 6px
  font-size: 14px
  &:disabled
    background: transparent
    color: var(--tg-theme-text-color)
    opacity: 0.7
  &:focus-visible
    outline: 0
    border-color: var(--tg-theme-button-color)

.calendar
  // background: var(--tg-theme-secondary-bg-color)
  div[class^="react-datepicker__header"]
    // background: var(--tg-theme-secondary-bg-color)
    background: white
    &:first-child
      border-bottom: 1px solid #cccccc
    h2
      // color: var(--tg-theme-text-color)
      margin-top: -6px
      font-weight: 400
      // background: var(--tg-theme-header-bg-color)

  div[class^="react-datepicker__month-container"]
    // background: var(--tg-theme-secondary-bg-color)
    background: white
  div[class^="react-datepicker__month"]
    padding: 4px
    // TODO
    // div[class^="react-datepicker__week"]
    //   div[class^="react-datepicker__day--selected"]
    //     background: red !important
  div[class^="react-datepicker__input-time-container"]
    // border-top: 1px solid var(--tg-theme-section-separator-color)
    border-top: 1px solid #cccccc
    // background: var(--tg-theme-secondary-bg-color)
    display: flex
    justify-content: space-between
    align-items: center
    margin: 0 4px
    padding: 8px 12px
    max-width: calc(100% - 8px)
    div
      margin: 0
      input
        border-color: #cccccc
        border-width: 1px
        border-radius: 4px
        padding: 4px
        margin: 0
        display: flex !important
        align-items: center
        color: #333

.time
  display: grid
  grid-auto-flow: column
  justify-content: start
  gap: 16px
  margin-top: 16px

.form
  display: grid
  align-content: space-between
  height: 100%

.inputs
  display: grid
  gap: 16px

.notificationControl
  display: flex
  justify-content: space-between
  align-items: center
  height: 36px
  margin-top: -8px
  .reminderTime
    display: grid
    gap: 6px
    grid-auto-flow: column
    align-items: center
    color: var(--tg-theme-subtitle-text-color)
    margin-top: 3px

.createButton
  width: 100%
  display: flex
  justify-content: center

.select
  width: 95px

.textNotTime
  color: var(--tg-theme-subtitle-text-color)
  font-size: 14px
  margin-bottom: 4px
  line-height: 16px
  margin: 4px 0 0 8px
  opacity: 0.8

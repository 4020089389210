.noteDetail
  display: flex
  flex-direction: column
  gap: 16px
  height: 100%
  min-height: 100vh

.noteDetailHeader
  position: sticky
  top: 0px
  z-index: 1
  background: var(--tg-theme-bg-color, white)
  color: var(--tg-theme-text-color)
  padding: 16px

.editorContainer
  padding: 0 16px 16px 16px
  flex: 1
  display: flex

.timeWheel
  position: relative
  width: auto

.selectedTime
  border-radius: 6px
  height: 36px
  border: 1px solid var(--tg-theme-section-separator-color)
  padding: 0 6px
  font-size: 14px
  width: 70px
  display: flex
  justify-content: center
  align-items: center
  background: white
  color: #333
  &.disabled
    background: transparent
    opacity: 0.7

.timeWheelLists
  display: flex
  align-items: center
  justify-content: center
  border: 1px solid var(--tg-theme-section-separator-color)
  border-radius: 8px
  width: 100px
  height: 158px
  overflow: hidden
  position: absolute
  bottom: 40px
  left: 0
  box-shadow: 0px 0px 10px 0px var(--tg-theme-text-color)
  z-index: 10

.wheelColumn
  flex: 1
  height: 100%
  overflow-y: auto
  scroll-behavior: smooth
  text-align: center
  background: white

  &::-webkit-scrollbar
    display: none

.wheelItem
  height: 35px
  cursor: pointer
  font-size: 14px
  transition: background 0.2s
  color: var(--tg-theme-text-color)
  display: flex
  justify-content: center
  align-items: center
  color: #333
  &.wheelItemDisabled
    opacity: 0.5

.selected
  font-weight: bold
  background: var(--tg-theme-button-color)
  color: var(--tg-theme-button-text-color)
  border-radius: 8px

.overlay
  position: fixed
  left: 0
  top: 0
  z-index: 9
  width: 100%
  height: 100vh
  max-height: 100%

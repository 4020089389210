.selectContainer
  position: relative

.select
  border-radius: 6px
  height: 36px
  border: 1px solid var(--tg-theme-section-separator-color)
  padding: 0 8px
  display: flex
  align-items: center
  justify-content: space-between
  padding: 0 4px
  color: #333
  background: white
  .selectedText
    font-size: 14px
    margin-right: 4px
  img
    transition: transform .2s

.selectList
  position: absolute
  width: 100%
  top: 38px
  left: 0
  padding: 0 6px
  border: 1px solid var(--tg-theme-section-separator-color)
  border-radius: 6px
  max-height: 160px
  overflow: auto
  color: #333
  background: white
  z-index: 5
  box-shadow: 0px 0px 4px 0px #333
  .selectOption
    font-size: 14px
    padding: 4px
    min-height: 30px
    transition: background-color .2s
    cursor: pointer
    &.selectOptionActive
      background-color: #e6e6e6

.selectActive
  img
    transform: scale(-1)

.overlay
  position: fixed
  left: 0
  top: 0
  min-width: 100%
  min-height: 100%
  z-index: 3

@media (hover: hover) and (pointer: fine)
  .selectOption:hover
    background-color: #e6e6e6

.switchContainer
  margin: 16px 0
  .react-switch-selector-option-unselected
    label
      color: #000000 !important
      opacity: 0.8
  label
    height: 28px
    font-size: 13px
    color: var(--tg-theme-button-text-color)

.ec-toolbar
  margin-bottom: 16px
  background: var(--tg-theme-bg-color)
  .ec-start
    margin: 0
    h2
      font-size: 16px
      font-weight: 400
  .ec-end
    margin: 0
    display: grid
    gap: 12px
    grid-auto-flow: column
    .ec-button
      &.ec-today
        background: var(--tg-theme-button-color)
        color: var(--tg-theme-button-text-color)
        border-radius: 6px
        height: 35px
        border: 0
        text-wrap: nowrap
        font-size: 14px
        margin: 0
        &:disabled
          opacity: 0.7
    .ec-button-group
      margin: 0
      display: grid
      gap: 12px
      grid-auto-flow: column
      .ec-button
        background: var(--tg-theme-button-color)
        color: var(--tg-theme-button-text-color)
        border-radius: 6px
        height: 35px
        border: 0
        font-size: 14px
        display: flex
        justify-content: center
        align-items: center
        .ec-icon
          display: flex
          justify-content: center
          align-items: center
          &.ec-prev
            &:after
              transform: rotate(-135deg) translate(-1px, 1px)
          &.ec-next
            &:after
              transform: rotate(45deg) translate(-1px, 1px)

.ec-day-view
  .ec-header
    border-radius: 4px 4px 0 0
    font-size: 14px
    .ec-day
      text-transform: capitalize
  .ec-all-day
    display: none
  .ec-body
    font-size: 14px
    border-radius: 0 0 4px 4px
    .ec-today
      background: transparent
      .ec-now-indicator
        border-color: #2ecc72db
        &::before
          background: #2ecc72db
    .ec-events
      margin: 0
      .ec-event
        background: var(--tg-theme-button-color)
        color: var(--tg-theme-button-text-color)
        .ec-event-body
          flex-direction: row-reverse
          justify-content: space-between
          .ec-event-time
            margin-top: 2px
            margin-right: 34px
          .ec-event-title
            font-size: 16px

.ec-time-grid .ec-time, .ec-time-grid .ec-line
  height: 70px

.ec-week-view
  .ec-body
    border-radius: 4px
    .ec-day
      .ec-day-head
        background: var(--tg-theme-bg-color)
        color: var(--tg-theme-text-color)
        font-size: 14px
      .ec-event-tag
        display: none

.taskInfo
  font-weight: 600

.closeEvent
  opacity: 0.8

.taskComment
  margin: 0
  font-size: 12px
  font-weight: 400
  margin-top: 6px
  opacity: 0.7

.taskClosed
  text-decoration: line-through

.toggleButton
  background: var(--tg-theme-button-color)
  color: var(--tg-theme-button-text-color)
  height: 35px
  width: 100%
  display: flex
  align-items: center
  justify-content: center
  border: 1px solid #d5d5d5
  border-top: 0
  text-wrap: nowrap
  padding: 0 16px
  font-size: 14px
  display: flex
  align-items: center
  &:disabled
    opacity: 0.7

.labelContainer
  display: flex
  flex-direction: column

.label
  color: var(--tg-theme-subtitle-text-color)
  font-size: 11px
  margin-bottom: 4px
  span
    color: #ed1414
    margin-left: -3px
